import UnAuthenticatedLayout from "@/views/Layouts/UnAuthenticated/UnAuthenticatedLayout.vue";
import BaseLayout from "@/views/Layouts/Authenticated/BaseLayout.vue";

// import modules routes
import defaultModuleRoutes from "./defaultModuleRoutes";
import adminModuleRoutes from "./adminModuleRoutes";
import dashboardModuleRoutes from "./dashboardModuleRoutes";
import resellerModuleRoutes from "./resellerModuleRoutes";
import filesExplorerModuleRoutes from "./filesExplorerModuleRoutes";
import inventoryModuleRoutes from "./inventoryModuleRoutes";
import salesModuleRoutes from "./salesModuleRoutes";
import purchasesModuleRoutes from "./purchasesModuleRoutes";
import crmModuleRoutes from "./crmModuleRoutes";
import auctionModuleRoutes from "./auctionModuleRoutes";
import spotModuleRoutes from "./spotModuleRoutes";
import configurationModuleRoutes from "./configurationModuleRoutes";
import bookingModuleRoutes from "./bookingModuleRoutes";
import pricingRuleModuleRoutes from "./pricingRuleModuleRoutes";
import customerModuleRoutes from "./customerModuleRoutes";

//import middleware
import guest from "@/middleware/guest";
import authorized from "@/middleware/authorized";
import permissions from "../constants/permissions";
import calendarModuleRoutes from "./calendarModuleRoutes";

// UnAuthenticatedPages
const NotFound = () => import("@/views/Pages/UnAuthenticated/NotFoundPage.vue");
const Login = () => import("@/views/Pages/UnAuthenticated/Login.vue");
const PasswordReset = () =>
  import("@/views/Pages/UnAuthenticated/Password/Reset.vue");
const PasswordEmail = () =>
  import("@/views/Pages/UnAuthenticated/Password/Email.vue");

const ListOrganizationPage = () =>
  import(
    "@/views/Pages/AdminModule/OrganizationManagement/ListOrganizationPage.vue"
  );

let unauthenticatedPages = {
  path: "/",
  component: UnAuthenticatedLayout,
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      meta: { middleware: guest },
    },
    {
      path: "/password/reset",
      name: "PasswordReset",
      component: PasswordReset,
      meta: { middleware: guest },
    },
    {
      path: "/password/email",
      name: "PasswordEmail",
      component: PasswordEmail,
      meta: { middleware: guest },
    },
  ],
};

const routes = [
  /** base routes */
  {
    path: "/",
    redirect: "/dashboard",
  },
  unauthenticatedPages,
  {
    path: "/not-found",
    component: NotFound,
  },
  {
    path: "/",
    component: BaseLayout,
    children: defaultModuleRoutes,
  },
  {
    path: "/",
    component: BaseLayout,
    children: dashboardModuleRoutes,
  },
  {
    path: "/files",
    component: BaseLayout,
    children: filesExplorerModuleRoutes,
  },
  {
    path: "/inventory",
    component: BaseLayout,
    children: inventoryModuleRoutes,
  },
  {
    path: "/crm",
    component: BaseLayout,
    children: crmModuleRoutes,
  },
  {
    path: "/auctions",
    component: BaseLayout,
    children: auctionModuleRoutes,
  },
  {
    path: "/sales",
    component: BaseLayout,
    children: salesModuleRoutes,
  },
  {
    path: "/purchases",
    component: BaseLayout,
    children: purchasesModuleRoutes,
  },
  {
    path: "/reseller",
    component: BaseLayout,
    children: resellerModuleRoutes,
  },
  {
    path: "/admin",
    component: BaseLayout,
    children: adminModuleRoutes,
  },
  {
    path: "/spot",
    component: BaseLayout,
    children: spotModuleRoutes,
  },
  {
    path: "/configuration",
    component: BaseLayout,
    children: configurationModuleRoutes,
  },
  {
    path: "/bookings",
    component: BaseLayout,
    children: bookingModuleRoutes,
  },
  {
    path: "/pricing-rules",
    component: BaseLayout,
    children: pricingRuleModuleRoutes,
  },
  {
    path: "/customers",
    component: BaseLayout,
    children: customerModuleRoutes,
  },
  {
    path: "/calendar",
    component: BaseLayout,
    children: calendarModuleRoutes,
  },
  {
    path: "/",
    component: BaseLayout,
    children: [
      {
        path: "/campings",
        name: "List Campings",
        components: { default: ListOrganizationPage },
        meta: {
          middleware: authorized,
          permission: permissions.PERM_VIEW_ANY_ORGANIZATIONS,
          hasLevel2: true,
        },
      },
    ],
  },
  {
    path: "**",
    redirect: "/not-found",
  },
];

export default routes;
