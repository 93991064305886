import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/login",
  registerUrl: "/register",
});

export default {
  state: {
    isAuthenticated: !!localStorage.getItem(
      "vue-authenticate.vueauth_access_token"
    ),
    namespaced: true,
    authToken: vueAuth.getToken(),
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    authToken(state) {
      return state.authToken;
    },
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
  },

  actions: {
    login(context, payload) {
      return vueAuth
        .login(payload.user, payload.requestOptions)
        .then((response) => {
          if (response?.data?.success) {
            return response?.data;
          } else if (response?.data?.access_token) {
            context.commit("isAuthenticated", {
              isAuthenticated: vueAuth.isAuthenticated(),
            });
            router.push({ path: "/dashboard" });
          }
        });
    },

    register(context, payload) {
      return vueAuth
        .register(payload.user, payload.requestOptions)
        .then((response) => {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated(),
          });
          router.push({ path: "/dashboard" });
        });
    },

    logout(context, payload) {
      try {
        if (vueAuth.isAuthenticated()) {
          return vueAuth.logout().then((response) => {
            context.commit("isAuthenticated", {
              isAuthenticated: vueAuth.isAuthenticated(),
            });
            router.push({ name: "Login" }).then(() => {
              localStorage.clear();
              location.reload();
            });
          });
        } else {
          router.push({ name: "Login" }).then(() => {
            localStorage.clear();
            location.reload();
          });
        }
      } catch (error) {
        router.push({ name: "Login" }).then(() => {
          localStorage.clear();
          location.reload();
        });
      }
    },
  },
};
